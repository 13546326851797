/* AllGamesCard.css */

.simplegame-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.simplegame-info {
  padding: 1rem;
  text-align: center;
}

.all-games-card {
  background-color: #31363f;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.all-games-card:hover {
  transform: translateY(-5px);
}

.simplegame-info h3 {
  margin-top: 10px;
  color: #d4a6ff;
}

.simplegame-info p {
  color: #8c8c8c;
}

.game-card-link {
  text-decoration: none;
  color: inherit;
  display: block; /* Ensure it wraps the card */
  width: 100%;
}
