.game-detail-container {
  padding: 2rem;
  color: #ffffff;
}

.game-banner {
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.game-title {
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 0 0 10px #ff69b4, 0 0 20px #9b49e8;
  text-align: center;
}

.section-heading {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #d4a6ff;
  font-weight: bold;
}

.game-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.game-stats {
  /* display: flex; */
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.platform-section {
  display: flex;
  margin-top: 0;
}

.platform-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.platform-icon {
  font-size: 1.2rem;
  color: #d4a6ff;
}

.platform-text {
  display: inline-block; /* Ensures each platform is treated as a block */
  margin: 0 5px; /* Adds spacing between platform names */
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #31363f;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize; /* Capitalizes the first letter */
}

.game-screenshots {
  margin-top: 2rem;
}

.screenshot-gallery {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
}

.screenshot {
  height: 200px;
  width: auto;
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.screenshot:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(212, 166, 255, 0.8);
}

.game-cta {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.play-button,
.back-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #ff69b4, #9b49e8);
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
}

.play-button:hover {
  background: linear-gradient(135deg, #ff4081, #6d23b6);
  transform: translateY(-5px);
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #d4a6ff;
}

.release-date {
  display: block;
  margin-top: 15px;
}

.rating-system {
  margin-top: 50px;
}

.rating-button:hover {
  color: gold;
  background-color: #060709;
}

.rating-button {
  color: gold;
  background-color: #31363f;
}

.rating-button:active {
  transform: scale(0.95);
  transition: transform 0.1s ease-in-out;
}

.rating-button.selected {
  /* background-color: #4caf50; */
  background-color: #6d23b6;
  /* color: white; */
  color: gold;
  border: 2px solid #3e8e41;
}

.review-header {
  text-align: center;
  color: #d4a6ff;
}

.review-talks {
  color: black;
}

.review-textarea {
  font-size: 15px;
}

.reviewer-link {
  color: #d4a6ff;
}

.delete-review-button {
  background-color: red;
  color: white;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  border-radius: 4px;
}
.delete-review-button:hover {
  background-color: darkred;
}

.login-text-prompt {
  margin-bottom: 30px;
}

.login-review-button {
  font-size: 14px;
  font-weight: 600;
  color: #f5f5f5;
  background-color: #ff69b4;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  /* margin-left: 200px; */
  margin-left: 0;
}

.login-review-button:hover {
  background-color: #ff4081;
}

/* Responsive Styling for Mobile Screens */
@media (max-width: 768px) {
  .game-details-page {
    padding: 0 10px; /* Add some padding for better spacing */
  }

  .game-details-page .game-entry {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center content */
    margin: 0 auto; /* Prevent horizontal overflow */
    width: 100%; /* Ensure full width on mobile */
    max-width: 1000px;
  }

  .game-details-page .game-image {
    width: 100%; /* Make the game image take full width */
    max-width: 300px; /* Prevent it from becoming too large */
    height: auto;
    margin-bottom: 15px; /* Add some spacing below the image */
  }

  .game-details-page .game-info {
    text-align: center; /* Center-align text content */
    width: 100%; /* Ensure the info box doesn't overflow */
    margin-inline-end: 40px;
  }

  .game-details-page .review-section {
    width: 100%; /* Full width for the review section */
    align-items: start; /* Add padding for better readability */
  }

  .game-details-page .review-entries {
    width: 100%; /* Prevent horizontal overflow */
  }

  .game-details-page .add-review textarea {
    width: 100%; /* Ensure text area fits within the screen */
  }
}

@media (max-width: 480px) {
  .game-details-page {
    padding: 0 5px; /* Reduce padding further on smaller screens */
  }

  .game-details-page .game-entry {
    padding: 10px;
  }

  .game-details-page .btn-favorite {
    width: 100%; /* Make the button take full width for better UX */
  }

  .game-details-page.rating-button {
    width: 100%; /* Rating buttons full width for touch accessibility */
  }
}
