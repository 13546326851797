.all-devs-container {
  padding: 2rem;
  background-color: #1a1a2e;
  color: #f0f0f0;
  text-align: center;
}

.dev-cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.all-devs-title {
  font-size: 2rem;
  margin: 1.5rem 0;
  color: #d4a6ff;
}

.dev-action-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.action-btn {
  background-color: #d4a6ff;
  border: none;
  padding: 10px 20px;
  color: #1a1a2e;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.action-btn:hover {
  background-color: #b392ff;
  color: #ffffff;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.pagination-button {
  background-color: #ffffff;
  color: #007bff;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button.active {
  background-color: #007bff;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .dev-cards-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

@media (max-width: 768px) {
  .dev-cards-list {
    grid-template-columns: repeat(1, 1fr); /* 1 column on mobile */
  }

  .action-btn {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .all-devs-title {
    font-size: 1.5rem;
  }

  .action-btn {
    font-size: 0.9rem;
  }

  .pagination-button {
    padding: 8px 12px; /* Smaller pagination buttons on mobile */
  }
}
