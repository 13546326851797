.blog-section {
  text-align: center;
  padding: 4rem 2rem;
  background: #1a1a2e;
}

.blog-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #76abae;
}

.blog-title .highlight {
  color: #d87dd7;
  font-weight: bold;
}

.blog-cards {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap; /* Adjust for responsiveness */
}

.blog-card {
  background: #31363f;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 350px;
  text-align: left;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-10px);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 1rem;
}

.blog-card-title {
  font-size: 1.2rem;
  color: #d4a6ff;
  margin-bottom: 0.5rem;
}

.blog-card-description {
  font-size: 0.9rem;
  color: #f5f5f5;
  margin-bottom: 1rem;
}

.blog-read-more {
  background: none;
  border: none;
  color: #d87dd7;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  display: inline-flex;
  align-items: center;
}

.blog-read-more:hover {
  text-decoration: underline;
}

.blog-create-button {
  margin-top: 50px;
  padding: 0.8rem 2rem;
  background-color: #d4a6ff;
  color: #333;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-create-button:hover {
  background-color: #9b49e8;
}
