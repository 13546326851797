/* Profile Container */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
}

.profile-info {
  text-align: center;
  margin-bottom: 30px;
}

.profilepicedit {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d4a6ff;
  color: #1a1a2e;
  transition: background-color 0.3s ease;
}

.edit-title {
  color: #d4a6ff;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.profile-username {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #d4a6ff;
}

.profile-email {
  font-size: 18px;
  color: #8c8c8c;
  margin-bottom: 25px;
}

.profile-buttons {
  gap: 20px;
}

.edit-profile-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-loading {
  color: #ffffff;
}

.edit-profile-form input {
  padding: 10px;
  font-size: 16px;
  width: 90%;
}

.edit-profile-form button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.delete-profile-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Profile Row */
.profile-row {
  margin-bottom: 3rem;
  text-align: center;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #d4a6ff;
}

/* Carousel Wrapper */
.profilecarousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

/* Carousel */
.profilecarousel {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding: 1rem 0;
  scroll-behavior: smooth;
}

.profilecarousel::-webkit-scrollbar {
  display: none;
}
/* Carousel Arrows */
.profilecarousel-arrow {
  position: relative;
  background-color: #d4a6ff;
  color: #1a1a2e;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.profilecarousel-arrow.left {
  margin-right: 10px;
}

.profilecarousel-arrow.right {
  margin-left: 10px;
}

.profilecarousel-arrow:hover {
  background-color: #b392ff;
}

/* Developer Cards */
.developer-card {
  background-color: #31363f;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.developer-card:hover {
  transform: translateY(-5px);
}

.developer-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.developer-name {
  font-size: 1.2rem;
  color: #d4a6ff;
  margin-bottom: 10px;
}

.view-profile-button {
  background-color: #d4a6ff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  color: #31363f;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-profile-button:hover {
  background-color: #b392ff;
  color: white;
}

/* Badge Cards */
.badge-card {
  background-color: #31363f;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.badge-card:hover {
  transform: translateY(-5px);
}

.badge-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.badge-name {
  font-size: 1rem;
  color: #d4a6ff;
}

.followers-count {
  margin-top: 20px;
  background-color: #3c3f47;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.followers-count h3 {
  font-size: 1.5rem;
  color: #d4a6ff;
}

.followers-count p {
  font-size: 1.2rem;
  color: #ffffff;
}

.no-badges {
  color: #ffffff;
  font-size: large;
}

.referral-title {
  color: #ffffff;
}

.referral-text {
  color: #d4a6ff;
  font-size: large;
}

.referral-code {
  color: #d4a6ff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profilecarousel {
    grid-template-columns: repeat(2, 1fr);
  }

  .profilecarousel-arrow {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }

  .developer-card,
  .badge-card {
    flex: 0 0 150px;
  }

  .developer-image {
    width: 80px;
    height: 80px;
  }

  .badge-icon {
    width: 50px;
    height: 50px;
  }
}
