/* Container and General Styling */
.contact-page {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 80px;
  text-align: center;
  color: #f0f0f0;
}

/* Title */
.contact-title {
  font-size: 2.5rem;
  color: #d4a6ff;
  margin-bottom: 1rem;
}

/* Description Text */
.contact-paragraph {
  font-size: 1rem;
  color: #b0b0b0;
  line-height: 1.6;
  max-width: 80%;
  margin: 0 auto 2rem;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-input {
  width: 80%;
  max-width: 600px;
  min-height: 150px;
  padding: 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  background-color: #2a2f3a;
  color: #f0f0f0;
  font-size: 1rem;
  resize: vertical; /* Allow resizing vertically */
}

.contact-input::placeholder {
  color: #8c8c8c; /* Lighter shade for placeholder */
}

/* Button Styling */
.contact-button {
  padding: 12px 24px;
  background-color: #d4a6ff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  color: #1a1a2e;
}

.contact-button:hover {
  background-color: #b392ff;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Small lift effect */
}

/* Alert Message Styling */
.custom-alert {
  background-color: #76abae;
  color: #1a1a2e;
  padding: 10px 20px;
  border-radius: 5px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-paragraph {
    font-size: 0.9rem;
    padding: 0 10px;
  }

  .contact-input {
    width: 90%;
  }

  .contact-button {
    font-size: 0.9rem;
    padding: 10px 18px;
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 1.8rem;
  }

  .contact-paragraph {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }

  .contact-input {
    width: 90%;
    padding: 12px;
  }

  .contact-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
