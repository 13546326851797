.blog-post {
  background-color: #1a1a2e;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.post-header {
  display: flex;
  align-items: center;
}

.post-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.post-footer {
  margin-top: 1rem;
}

.post-footer button {
  background-color: #d4a6ff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}
