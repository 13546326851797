/* Overall page container */

.allgames-container {
  background-color: #1a1a2e;
}

.allgames-page {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.currentItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Search box styling */
.search-box {
  width: 100%;
  max-width: 300px;
  margin-top: 100px;
  margin-bottom: 30px;
  margin-left: 500px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d4a6ff;
  outline: none;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.filters button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: #d4a6ff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filters button:hover {
  background-color: #b392ff;
  color: #ffffff;
}

/* Game card entry */
.game-entry {
  display: flex;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #31363f;
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.game-image {
  width: 200px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.game-info {
  flex-grow: 1;
}

.game-title {
  font-size: 22px;
  font-weight: bold;
  color: #d4a6ff;
  margin-bottom: 8px;
}

.game-developer {
  font-size: 16px;
  color: #76abae;
  margin-bottom: 15px;
}

.game-description {
  font-size: 14px;
  color: #d0d0d0;
  margin-bottom: 15px;
}

.game-details li {
  color: #b0b0b0;
  margin: 3px 0;
}

/* Favorites button */
.btn-favorite {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #d4a6ff;
  border: none;
  border-radius: 5px;
  color: #1a1a2e;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-favorite:hover {
  background-color: #b392ff;
  color: #ffffff;
}

/* Rating system */
.rating-system {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.rating-button {
  padding: 8px 10px;
  background-color: #fff;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.rating-button:hover,
.rating-button.selected {
  background-color: #76abae;
  color: white;
}

.rating-count {
  font-size: 12px;
  color: #333;
}

/* Review section */
.review-section {
  width: 100%;
  background-color: #3c3f47;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.review-title {
  font-size: 24px;
  font-weight: bold;
}

.add-review {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.add-review textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #b392ff;
  outline: none;
  resize: none;
}

.add-review button {
  padding: 8px 12px;
  background-color: #d4a6ff;
  border: none;
  border-radius: 5px;
  color: #1a1a2e;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.add-review button:hover {
  background-color: #b392ff;
  color: #ffffff;
}

.add-review img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

::placeholder {
  color: #31363f;
  font-size: 16px;
}

.review-entries {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  padding: 10px;
  max-height: 150px;
  overflow-y: scroll;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-entry {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  background-color: #76abae;
  border-radius: 5px;
}

.review-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.review-username {
  font-weight: bold;
}

.review-comment {
  font-size: 14px;
  color: black;
}

.review-content {
  display: flex;
  flex-direction: column;
}

.three-dot-icon {
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
  /* color: #333; */
}

.review-options {
  position: relative;
  display: inline-block;
}

.delete-option {
  position: absolute;
  top: 25px;
  right: 0;
  background-color: white;
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.delete-option:hover {
  background-color: #f8d7da;
}

/* Pagination styling */
.pagination {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px;
  background-color: #d4a6ff;
  color: #1a1a2e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #b392ff;
  color: #ffffff;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .search-box {
    margin: 20px auto;
    max-width: 90%;
  }

  .game-entry {
    flex-direction: column;
    align-items: center;
    /* margin: 10px auto; */
    margin-right: 100px;
  }

  .currentItems {
    flex: 0 0 100%; /* One card per row */
  }

  .game-info {
    text-align: center;
  }

  .rating {
    font-size: 16px;
  }

  .pagination-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .rating-button {
    width: 100%;
  }

  .currentItems {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row for tablets */
  }
}

@media (max-width: 480px) {
  .search-box {
    padding: 20px;
    height: auto;
  }

  .game-entry {
    margin: 10px auto;
    max-width: 100%;
  }

  .game-image {
    height: auto;
    margin-bottom: 10px;
  }

  .game-info {
    text-align: center;
  }

  .rating {
    font-size: 14px;
  }

  .pagination-button {
    padding: 6px 10px;
    font-size: 10px;
  }

  .rating-button {
    width: 100%;
  }
  .currentItems {
    grid-template-columns: 1fr; /* 1 card per row for mobile */
  }
}
