/* Navbar Container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1a1a2e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo */
.navbar-logo {
  font-family: "RetroFont", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #d4a6ff;
  text-decoration: none;
}

/* Navbar Links */
.navbar-center {
  display: flex;
  gap: 30px;
}

.navbar-link {
  font-size: 16px;
  font-weight: 500;
  color: #f5f5f5;
  text-decoration: none;
  position: relative;
}

.navbar-link:hover {
  /* content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px; */
  color: #d4a6ff;
}

.navbar-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Right Section */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-button {
  font-size: 14px;
  font-weight: 600;
  color: #f5f5f5;
  background-color: #ff69b4;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar-button:hover {
  background-color: #ff4081;
}

.navbar-link {
  margin-right: 10px;
}

.hamburger-icon {
  display: none;
  cursor: pointer;
}

.hamburger-icon .icon {
  font-size: 24px;
  color: white;
}

.navbar-center.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #1a1a2e;
  padding: 20px;
  z-index: 999;
  height: 250px;
}

.signout-btn {
  font-size: 14px;
  font-weight: 600;
  color: #f5f5f5;
  background-color: #ff69b4;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  /* margin-left: 200px; */
  margin-left: 0;
}

.signout-btn:hover {
  background-color: #ff4081;
}

.signout-btn2 {
  font-size: 14px;
  font-weight: 600;
  color: #f5f5f5;
  background-color: #ff69b4;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  /* margin-left: 20px; */
  margin-left: 0;
}

.signout-btn2:hover {
  background-color: #ff4081;
}

/* Bell Icon and Red Bubble */
.notifications-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
}

.bell-icon {
  font-size: 1.5rem;
  margin-left: 0;
  color: #ffffff;
  cursor: pointer;
}

.notification-bubble {
  position: absolute;
  top: 0;
  right: -5px;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Notifications Dropdown */
.notifications-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #1a1a2e;
  color: white;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #31363f;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-message {
  font-size: 0.9rem;
}

.no-notifications {
  text-align: center;
  padding: 20px;
  color: #d4a6ff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-center {
    width: 100%;
    display: none;
    margin-top: 32px;
  }

  .navbar-right {
    justify-content: space-between;
    width: 100%;
  }

  .hamburger-icon,
  .bell-icon {
    margin-top: 0;
  }

  .hamburger-icon {
    display: block;
    margin-bottom: 10px;
  }

  .notifications-dropdown {
    left: 20px; /* Align with the edge of the screen */
    top: 45px;
  }

  .navbar-link {
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .navbar-right {
    flex-wrap: wrap; /* Prevent items from overflowing */
  }

  .signout-btn2 {
    width: 100%; /* Button takes full width on small screens */
    text-align: center;
  }
}
