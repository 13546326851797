.leaderboard-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #1a1a2e;
  border-radius: 8px;
  color: #fff;
}

.leaderboard-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #ffd700;
}

.leaderboard-text {
  color: #fff;
  text-align: center;
}

.leaderboard-list {
  list-style: none;
  padding: 0;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: #2a2a3e;
  border-radius: 8px;
}

.leaderboard-rank {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
  color: #ffd700;
}

.leaderboard-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.leaderboard-info {
  flex: 1;
}

.leaderboard-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.leaderboard-points {
  font-size: 1rem;
  color: #ffd700;
}

.leaderboard-subtitle {
  color: #d4a6ff;
}
