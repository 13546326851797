.dev-profile-card {
  background-color: #31363f;
  color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  max-width: 350px;
  margin: 0 auto 1.5rem;
  text-align: center;
}

.dev-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.dev-name {
  font-size: 1.5rem;
  color: #d4a6ff;
  margin-bottom: 0.5rem;
}

.dev-bio {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.dev-website a,
.dev-socials a {
  color: #76abae;
  text-decoration: none;
  font-size: 1.3rem;
  margin: 10px;
}

.dev-website a:hover,
.dev-socials a:hover {
  color: #d4a6ff;
}

/* .dev-games h4 {
  color: #f0f0f0;
  margin: 1rem 0 0.5rem;
} */

.dev-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.follow-button,
.view-dev-button {
  background-color: #d4a6ff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  color: #1a1a2e;
  transition: background-color 0.3s;
}

.follow-button:hover,
.view-dev-button:hover {
  background-color: #b392ff;
  color: #ffffff;
}

.games-title {
  font-weight: bold;
  margin-top: 10px;
  color: #d4a6ff;
}

.games-list {
  list-style-type: disc;
  padding-left: 5px;
  color: #ffffff;
  text-decoration: none;
}

.games-list li {
  margin-bottom: 5px;
}

.dev-job {
  font-size: 1.3rem;
  color: #d4a6ff;
}

@media (max-width: 768px) {
  .dev-profile-card {
    flex: 0 0 45%; /* Two cards per row */
    height: auto;
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .dev-profile-card {
    flex: 0 0 100%; /* One card per row */
  }
}
