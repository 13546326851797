/* Overall Page Container */
.dev-page {
  max-width: 900px; /* Reduce width for better focus */
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  background-color: #121212; /* Darker background for a minimalist feel */
  color: #e0e0e0; /* Light grey text for better contrast */
  font-family: "Roboto", sans-serif; /* Clean, modern font */
}

/* Page Title */
.devpage-title {
  font-size: 2.4rem;
  margin: 2rem 0;
  color: #8c75ff; /* Subtle purple for a minimalist accent */
  text-align: center;
  font-weight: 600;
}

/* Developer Profile */
.dev-profile {
  padding: 20px;
  text-align: center;
  background-color: #1c1c1e; /* Slightly lighter background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.devprofile-edit {
  margin-top: 20px;
}

.devedit-button {
  margin-bottom: 20px;
}

/* Profile Picture */
.devprofile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #8c75ff; /* Accent border */
  margin-bottom: 15px;
}

/* Developer Name and Bio */
.dev-name {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.dev-bio {
  font-size: 1rem;
  color: #b3b3b3; /* Softer color for secondary text */
  line-height: 1.6;
  margin-bottom: 20px;
}

.editing-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #202024;
  color: #ffffff;
  font-size: 1rem;
  outline: none;
}

/* Polls Section */
.devpoll-title {
  font-size: 1.6rem;
  margin-bottom: 15px;
  color: #8c75ff;
  font-weight: 500;
}

.devpoll-create input {
  width: 100%;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #202024;
  color: #ffffff;
  font-size: 1rem;
  outline: none;
}

.devpoll-create ::placeholder {
  color: #777;
}

.devpoll-create input:focus {
  border-color: #8c75ff;
  box-shadow: 0 0 4px rgba(140, 117, 255, 0.5);
}

.devpoll-list {
  margin-top: 20px;
}

.polldelete2-button {
  margin-top: 10px;
}

.addoption-button {
  margin-top: 20px;
}

.add-update-button {
  margin-top: 10px;
}

/* Updates Section */
.developer-updates-section {
  margin-top: 30px;
}

.update-title {
  font-size: 1.6rem;
  color: #8c75ff;
  margin-bottom: 15px;
}

.update-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #25252a; /* Neutral card background */
  border-radius: 8px;
  color: #e0e0e0;
  font-size: 0.95rem;
  line-height: 1.5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.update-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #202024;
  color: #ffffff;
  font-size: 1rem;
  outline: none;
}

.update-form ::placeholder {
  color: #777;
}

/* Comments Section */
.developer-comments-section {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #333;
}

.comments-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #8c75ff;
  font-weight: 500;
}

.comment-item {
  display: flex;
  gap: 15px;
  padding: 15px;
  background-color: #1e1e22; /* Slightly lighter than page background */
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333; /* Placeholder background for missing images */
}

.comment-details {
  flex-grow: 1;
}

.comment-username {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 5px;
}

.comment-text {
  color: #b3b3b3;
  font-size: 0.9rem;
  line-height: 1.5;
}

.no-comments-message {
  text-align: center;
  font-size: 1rem;
  color: #777;
}

/* Minimalist Buttons */
button {
  padding: 10px 20px;
  background-color: #8c75ff;
  border: none;
  border-radius: 6px;
  /* color: #ffffff; */
  color: #1e1e22;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s ease;
  margin: 1px;
}

button:hover {
  background-color: #a58aff;
}

.nodevprofile {
  text-align: center;
}
