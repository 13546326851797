/* General Styling */
.game-discovery-section {
  padding: 3rem 2rem;
  text-align: center;
  display: block;
}

.game-discovery-header {
  margin-bottom: 2rem;
}

.gamesection-title {
  font-size: 2.8rem;
  font-weight: bold;
  color: #d4a6ff;
  margin-bottom: 2rem;
}

.carousel-item-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

/* Carousel Sections */
.game-carousel-section {
  margin-bottom: 3rem;
}

.carousel-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #d4a6ff;
}

.discovery-game-carousel {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  padding: 1rem 0;
}

.carousel::-webkit-scrollbar {
  display: none;
}

/* Game Card */
.discovery-game-card {
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 1.5rem;
  /* width: 300px; */
  width: 90%;
  height: 410px;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.discovery-game-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(212, 166, 255, 0.8);
}

.discovery-game-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.discovery-game-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
}

.discovery-game-genre {
  font-size: 1rem;
  color: #a0a0a0;
}

.discovery-hover-description {
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #ff69b4;
}

.discovery-platform-icons {
  margin-top: 1rem;
  display: flex;
  gap: 8px;
  justify-content: center;
}

.discovery-game-link {
  text-decoration: none;
}

.section-divider {
  border: none;
  border-top: 1px solid #d4a6ff; /* Subtle divider */
  margin: 1rem 0;
}

.past-title {
  color: #ffffff;
  font-size: large;
}

.see-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #ff69b4, #9b49e8);
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, transform 0.2s;
}

.see-button:hover {
  background: linear-gradient(135deg, #ff4081, #6d23b6);
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .discovery-game-carousel {
    display: grid; /* Ensure display: grid for the responsive layout */
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  }
}

@media (max-width: 768px) {
  .gamesection-title {
    font-size: 2rem;
  }

  .carousel-title {
    font-size: 1.5rem;
  }

  .discovery-game-carousel {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }

  .discovery-game-card {
    padding: 1rem; /* No flexbox properties here */
  }

  .discovery-game-image {
    height: 120px;
  }

  .discovery-game-title {
    font-size: 1.2rem;
  }

  .discovery-game-subtitle {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .discovery-game-carousel {
    grid-template-columns: 1fr;
  }

  .discovery-game-card {
    padding: 1rem;
  }

  .discovery-game-title {
    font-size: 1rem;
  }

  .discovery-game-subtitle {
    font-size: 0.8rem;
  }
}
