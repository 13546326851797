.header-container {
  position: relative;
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6),
      rgba(26, 26, 46, 0.9)
    ),
    url("/public/images/Heroheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.header-title {
  font-size: 4rem;
  font-weight: bold;
  color: #d4a6ff;
  text-shadow: 0 0 15px #ff69b4, 0 0 30px #9b49e8;
  font-family: "RetroFont", sans-serif;
  margin-bottom: 1rem;
}

.header-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #f5f5f5;
}

.header-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.explore-now-button,
.learn-more-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #ff69b4, #9b49e8);
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, transform 0.2s;
}

.explore-now-button:hover,
.learn-more-button:hover {
  background: linear-gradient(135deg, #ff4081, #6d23b6);
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
  }

  .header-subtitle {
    font-size: 1rem;
  }

  .header-btn {
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 2rem;
  }

  .header-subtitle {
    font-size: 0.9rem;
  }

  .header-btn {
    padding: 8px 16px;
  }
}
