.about-section {
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.about-content {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.about-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-image {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-text {
  flex: 1;
  text-align: left;
  max-width: 500px;
}

.about-title {
  font-size: 1.2rem;
  color: #d4a6ff;
  margin-bottom: 0.5rem;
}

.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.about-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
}

.about-button {
  padding: 0.8rem 2rem;
  background-color: #d4a6ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: #9b49e8;
}
