.favorites-title {
  padding: 20px;
  font-size: 35px;
  color: #76abae;
  text-align: center;
}

.pagination {
  text-align: center;
  margin-bottom: 20px;
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .favorites-title {
    font-size: 28px;
    padding: 15px;
  }

  .game-entry {
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
  }

  .game-info {
    text-align: center;
  }

  .rating {
    font-size: 16px;
  }

  .pagination-button {
    padding: 8px 12px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .favorites-title {
    font-size: 24px;
    padding: 10px;
  }

  .game-entry {
    margin: 10px auto;
    max-width: 100%;
  }

  .game-image {
    height: auto;
    margin-bottom: 10px;
  }

  .game-info {
    text-align: center;
  }

  .rating {
    font-size: 14px;
  }

  .pagination-button {
    padding: 6px 10px;
    font-size: 10px;
  }
}
