/* Developer Profile Section */
.developer-profile-section {
  padding: 4rem 2rem;
  background-color: #1a1a2e;
  text-align: center;
}

/* Carousel Section */
.developer-carousel-section {
  margin-bottom: 3rem;
  position: relative;
}

/* Titles */
.carousel-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #d4a6ff;
}

/* Carousel */
.carousel {
  display: flex;
  overflow-x: auto;
  gap: 1.5rem;
  padding: 1rem 0;
}

.carousel::-webkit-scrollbar {
  display: none;
}

/* Profile Cards */
.profile-card {
  flex: 0 0 250px;
  background-color: #31363f;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.profile-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.profile-card .developer-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.5rem;
}

.profile-card .developer-subtitle {
  font-size: 1rem;
  color: #bbb;
}

.developer-bio {
  color: #fff;
}

/* See All Button */
.see-all-button {
  position: absolute;
  bottom: -90px;
  right: 810px;
  background-color: #d4a6ff;
  color: #1a1a2e;
  font-size: 1.3rem;
  /* font-weight: bold; */
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.see-all-button:hover {
  background-color: #c495e8;
}

.view-dev-button,
.follow-button {
  font-size: 1rem;
  margin: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .carousel-title {
    font-size: 1.5rem;
  }

  .profile-card {
    padding: 0.8rem;
  }

  .profile-card img {
    width: 80px;
    height: 80px;
  }

  .profile-card .developer-name {
    font-size: 1.2rem;
  }

  .profile-card .developer-subtitle {
    font-size: 0.9rem;
  }

  .see-all-button {
    position: static; /* Remove absolute positioning */
    margin: 20px auto;
    font-size: 1rem; /* Slightly smaller text for mobile */
  }
}
